

<div class="container-fluid main-container" [ngStyle]="{height: height}">
    <h1 class="title">Browse</h1>  
    <!-- Sections  -->
    <a class="feature-title" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/directory">Categories</a>
    <a class="feature-title" routerLinkActive="active" routerLink="/directory/all">Live Channels</a>
    <!-- Search -->
    <div class="container-search-bar">
        <p class="title" >Filter by </p>
        <app-search-bar></app-search-bar>
    </div>

    <!-- Content -->
    <router-outlet ></router-outlet>
</div>
