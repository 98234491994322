<div class="container-fluid" (window:resize)="onResize()">
    <div class="row">
        <div class="col-sm-12 nopadding">
            <app-header></app-header>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-2 nopadding">
            <app-simple-channel-list></app-simple-channel-list>
        </div>
        <div class="col-xs-10 nopadding">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

