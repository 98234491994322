

<div class="container-fluid">
    <div class="row mainContainer">
        <div
            class="col-sm-9 nopadding page"
            [ngStyle]="{height: heightChannel}"
            (window:resize)="onResize()"
            #videoContainer>

            <!-- Header -->
            <app-header-channel [channel]="channel" ></app-header-channel>
            <!-- Video -->
          <iframe #iframe
                  *ngIf="channel && channel.type == TYPE_3D_MODEL"
                  [width]="this.width"
                  [height]="this.height"
                  [src]="urlSafe"
                  ></iframe>
          <youtube-player
              *ngIf="channel && channel.type == TYPE_YOUTUBE"
              [videoId]="channel.content"
              (ready)="savePlayer($event)"
              (change)="onStateChange($event)"
              [width]="width"></youtube-player>

          <div class="info">
              <p>People: {{ totalPeople }}</p>
          </div>


      </div>

      <div class="col-sm-3 nopadding">
          <!-- Chat    -->
            <app-chat *ngIf="!smallScreen && channel" [channel]="channel"></app-chat>
        </div>
    </div>
</div>
