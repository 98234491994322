
<div class="mainContainer" (window:resize)="onResize()" [ngStyle]="{height: heightChat}">
    <!-- Header -->
    <div class="header">
        <p class="title">CHAT</p>
    </div>

    <!-- Comments -->
    <div class="contentContainer" (scroll)="onScroll($event)" #chatContainer>
        <div class="commentContainer" *ngFor="let comment of comments" >
            <!--<p>{{ comment.getTime() }}</p>-->
            <!--<p [ngStyle]="{color: comment.getColor()}">{{ comment.author}}:</p>-->
            <p >{{ comment.author}}:</p>
            <p class="comment">{{ comment.comment}}</p>
        </div>
    </div>

    <!-- Enter comment -->
    <div class="inputCommentContainer">
        <p style="color: white;">Time: {{ timeSec.toFixed(2) }}</p>
        <div class="form-group">
            <input
                class="form-control active-purple"
                type="text"
                placeholder="Send a message"
                (keyup.enter)="addInput()"
                #inputChat>
        </div>
    </div>

</div>
