
<div class="main-container" #header>
  <div class="navbar-header">
    <!-- Brand -->
    <p class="navbar-brand"><strong>Simple Twitch</strong> </p>
    <!-- Features -->
    <ul class="nav navbar-nav list">
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/" >Discover</a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/directory">Browse</a>
        </li>
    </ul>
    

  </div>
  <app-search-bar></app-search-bar>

</div>
